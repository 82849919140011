import { Injectable } from '@angular/core';
import { ProfileType } from '@core/models/utils.model';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MenuItem, OrigenMenu } from '@core/models/user-roles.model';
import { environment } from 'src/environments/environment';

const TOKEN_KEY = 'token-api';
const USER_PROFILE = 'user-profile';
const USER_GROUPS = 'user-groups';
const LIST_CARANTYHOST = 'list-carantyhost';
const LIST_ALLCARANTYHOST = 'listall-carantyhost';
const LIST_SCOPES = 'list-scopes';
const RESOURCES = 'resources-crm';

@Injectable({
  providedIn: 'root',
})
export class StoreLocalStorageAuthService {
  profile: ProfileType;
  profileToken: any = {};
  menuSubject = new BehaviorSubject<MenuItem[]>([]);
  menu$ = this.menuSubject.asObservable();

  // listCarantyHostSubject = new BehaviorSubject<ProfileType[]>([]);
  // listCarantyHost$ = this.listCarantyHostSubject.asObservable();

  groupsRolSubject = new BehaviorSubject<String[]>([]);
  groupsRol$ = this.groupsRolSubject.asObservable();

  constructor(private _http: HttpClient) {}

  logoutRemove(): void {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(RESOURCES);
  }

  setScopesUser(scopes: string[]): void {
    localStorage.removeItem(LIST_SCOPES);
    localStorage.setItem(LIST_SCOPES, JSON.stringify(scopes));
  }

  getScopesUser(): string[] {
    return JSON.parse(localStorage.getItem(LIST_SCOPES) ?? '[]');
  }

  saveToken(token: string): void {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.setItem(TOKEN_KEY, token);
  }

  getToken(): string | null {
    return localStorage.getItem(TOKEN_KEY);
  }

  getDataToGetNewToken(): any {
    return this.profileToken;
  }

  setDataToGetNewToken(profileToken: any): any {
    this.profileToken = profileToken;
  }

  savedResources(resources) {
    localStorage.removeItem(RESOURCES);
    localStorage.setItem(RESOURCES, JSON.stringify(resources));
  }

  getListResources(item: string) {
    try {
      const resorces = JSON.parse(localStorage.getItem(RESOURCES));
      return resorces[item];
    } catch (error) {
      return undefined;
    }
  }

  getByRolUser(origen: string, user: string) {
    return this._http.get(
      `${environment.WebApiUrlCarantyamdev}/menu/usuario?nombre_menu=${origen}&user_email=${user}&backend=azure`
    );
  }
}
