import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { LogAudit, ResponseBack, ResponseBackAzure } from '@core/models/responsehttp.model';
import { OrigenMenu } from '@core/models/user-roles.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CatalogosGeneralesService {
  private readonly _http = inject(HttpClient);

  httpParam = new HttpParams();
  httpHeaders = new HttpHeaders();

  constructor() {
    this.httpParam = this.httpParam.append('backend', 'azure');
  }

  getDataBanks() {
    return this._http.get<ResponseBackAzure>(`${environment.WebApiUrlCarantyamdev}/banks/getBanks`);
  }

  //LOG_AUDIT
  getLogAudit(id?: string) {
    let httpParam = new HttpParams();
    httpParam = httpParam.append('backend', 'azure');
    httpParam = httpParam.append('id_contrato', id ?? '');

    return this._http.get<ResponseBackAzure>(`${environment.WebApiUrlCarantyamdev}/log/crm`, { params: httpParam });
  }

  postLogAudit(data: LogAudit) {
    let httpParam = new HttpParams();
    httpParam = httpParam.append('backend', 'azure');

    return this._http.post<ResponseBackAzure>(`${environment.WebApiUrlCarantyamdev}/log/crm`, data, {
      params: httpParam,
    });
  }

  getListadoUsuariosCRM() {
    return this._http.get<ResponseBackAzure>(`${environment.WebApiUrlCarantyamdev}/crm/usuarios?backend=azure`);
  }

  //ROLES
  getCarantyHostQuienPuedeVerAquien(usuario: string, origen: OrigenMenu) {
    return this._http.get<ResponseBackAzure>(
      `${environment.WebApiUrlCarantyamdev}/citas/permisos/detalle/${usuario}/${origen}?backend=azure`
    );
  }

  getResourcesCRM() {
    return this._http.get<ResponseBackAzure>(`${environment.WebApiUrlCarantyamdev}/resources/crm?backend=azure`);
  }

  getShowRoomsArray() {
    return this._http.get<ResponseBack>(`${environment.WebApiUrlCarantyamdev}/showroom/getShowRoomsArray`);
  }
}
