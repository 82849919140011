import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, tap } from 'rxjs';
import { ProfileType } from '@core/models/utils.model';

@Injectable({
  providedIn: 'root',
})
export class GraphService {
  GRAPH_ENDPOINT = environment.msalAuth.graphEndpoint;

  private groupsSubject = new BehaviorSubject<String[]>([]);
  public groups$ = this.groupsSubject.asObservable();

  private profileSubject = new BehaviorSubject<ProfileType | null>({});
  public profile$ = this.profileSubject.asObservable();

  constructor(protected _http: HttpClient) {}

  getAllUsersCompany() {
    return this._http.get<{ value: ProfileType[]; '@odata.context': string }>(`${this.GRAPH_ENDPOINT}/users`);
  }

  getDirectReportUser(email: string) {
    return this._http.get(`${this.GRAPH_ENDPOINT}/users/${email}/directReports`);
  }

  getDirectReportMe() {
    return this._http.get(`${this.GRAPH_ENDPOINT}/me/directReports`);
  }

  recursive(userId, outputArray, controlBucle) {
    this.getDirectReportUser(userId).subscribe((response: any) => {
      const users = response.value;
      if (users.length > 0) {
        users.forEach((user: any) => {
          let index = controlBucle.findIndex((e) => e.id === user.id);

          if (index === -1) {
            controlBucle.push(user);
            outputArray.next(user);
            this.recursive(user.id, outputArray, controlBucle);
          }
        });
      }
    });
  }

  getMeProfile() {
    return this._http.get<ProfileType>(`${this.GRAPH_ENDPOINT}/me`);
  }

  getMeProfileData() {
    return this._http.get(`${this.GRAPH_ENDPOINT}/me?$select=id,userPrincipalName,customSecurityAttribute`);

    return this._http.get(
      `${this.GRAPH_ENDPOINT}/users/d52ac003-3108-4dc8-b580-1f709de3a5e2?$select=id,userPrincipalName,customSecurityAttributes`
    );
  }

  getDataMeProfile() {
    return this._http.get(`${this.GRAPH_ENDPOINT}/me`).pipe(
      tap((response: any) => {
        this.profileSubject.next(response);
      }),
      tap(() => {
        this.getMyGroups().subscribe((groups: any) => {
          if (groups.value.length > 0) {
            const groupD = groups.value.map((group) => group.displayName);
            this.groupsSubject.next(groupD);
          }
        });
      })
    );
  }

  allMyCalendars(userPrincipalName: string) {
    return this._http.get(`${this.GRAPH_ENDPOINT}/users/${userPrincipalName}/calendars`);
  }

  addEventsCalendarMe(bodyEventOutlookCalendar: any) {
    return this._http.post(`${this.GRAPH_ENDPOINT}/me/events`, bodyEventOutlookCalendar);
  }

  getMyGroups() {
    return this._http.get<ResponseApiGraph>(`${this.GRAPH_ENDPOINT}/me/memberOf`);
  }

  getMembersGroups(id: string) {
    return this._http.get<ResponseApiGraph>(`${this.GRAPH_ENDPOINT}/groups/${id}/members`);
  }

  sendMail(body: any) {
    return this._http.post(`${this.GRAPH_ENDPOINT}/me/sendMail`, body);
  }
}

export interface ResponseApiGraph {
  value: unknown;
  '@odata.context': string;
}
